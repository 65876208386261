import { configureScope } from '@sentry/nextjs'
import LogRocket from 'logrocket'
import LogRocketReact from 'logrocket-react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import '../styles/tailwind.scss'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

export default function AlchemyApp({ Component, pageProps }) {
  return (
    <QueryClientProvider client={queryClient}>
      <Component {...pageProps} />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

// Initialize LogRocket
LogRocket.init('0hsrzc/alchemy')
// Setup LogRocket React tracking
LogRocketReact(LogRocket)
// Link LogRocket with Sentry
LogRocket.getSessionURL((sessionURL) => {
  configureScope((scope) => {
    scope.setExtra('sessionURL', sessionURL)
  })
})
